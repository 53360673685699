import React, { useEffect, useMemo, useState } from "react";
import { Table } from "antd";
import { Icon } from "@iconify/react";
import moment from "moment-timezone";
import "./style.css";
import { useAppContext } from "../../plugin/AppContext";
import flights from "../../models/flights";
import Loader from "../../plugin/loader/loader";

const center = {
  lat: 25.0732972,
  lng: 55.728425,
};

const params = {
  ...center,
  // lat: 25.248665,
  // lng: 55.352917,
  // lat: 24.973472000000072,
  // lng: 56.06838900000008,
  radius: 150,
  limit: 50,
  airport: "DXB",
};

const AircraftDataTable = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const { aircraftsData } = useAppContext();

  const column = [
    {
      title: "SEQUENCE",
      dataIndex: "sequence",
      key: "sequence",
      fixed: "left",
      width: 90,
      sorter: (a, b) => a.sequence - b.sequence,
      defaultSortOrder: "ascend",
      // sortDirections: ["ascend"],
      // render: (_, __, index) => (page - 1) * 5 + index + 1,
    },
    {
      title: "ARRIVAL",
      children: [
        {
          title: "IMPED",
          dataIndex: ["arrivingFrom"],
          key: "Imped",
          fixed: "left",
          width: 80,
          render: (arrivingFrom, row) =>
            arrivingFrom.node === "IMPED" ? row?.identification?.callsign : "",
        },
        {
          title: "DB529",
          dataIndex: ["arrivingFrom"],
          key: "DB529",
          fixed: "left",
          width: 80,
          render: (arrivingFrom, row) =>
            arrivingFrom.node === "VUTEB" ? row?.identification?.callsign : "",
        },
        {
          title: "KEBOG",
          dataIndex: ["arrivingFrom"],
          key: "KEBOG",
          fixed: "left",
          width: 80,
          render: (arrivingFrom, row) =>
            arrivingFrom.node === "ITLAB" ? row?.identification?.callsign : "",
        },
        {
          title: "LORID",
          dataIndex: ["arrivingFrom"],
          key: "LORID",
          fixed: "left",
          width: 80,
          render: (arrivingFrom, row) =>
            arrivingFrom.node === "LORID" ? row?.identification?.callsign : "",
        },
      ],
    },
    {
      title: "AIRCRAFT TPYE",
      dataIndex: "aircraft",
      key: "aircraft",
      render: (aircraft) => aircraft?.model?.code,
    },
    {
      title: "CATEGORY",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "SEPARATION (NM)",
      children: [
        {
          title: (
            <div className="d-flex align-items-center flex-column">
              <span>MNM</span>
              <span>4</span>
            </div>
          ),
          dataIndex: ["separation"],
          key: "separation",
          width: 60,
          render: (separation) => separation / 22,
        },
        {
          title: (
            <div className="d-flex align-items-center flex-column">
              <span>+</span>
              <span>1</span>
            </div>
          ),
          dataIndex: ["safeSeparation"],
          key: "safeSeparation",
          width: 60,
          render: (safeSeparation) => safeSeparation / 22,
        },
      ],
      // dataIndex: "separation",
      // key: "separation",
      // render: (separation) => separation / 24,
    },
    {
      title: "GAP AT 4 DME",
      dataIndex: "aircraftGap",
      key: "aircraftGap",
      render: (aircraftGap) =>
        (Math.floor(Number(aircraftGap) * 10) / 10).toFixed(1),
    },
    {
      title: "ESTIMATE ARR. TIME (UTC)",
      dataIndex: "estimateArrivalTime",
      key: "estimateArrivalTime",
      render: (estimateArrivalTime) => {
        let arrTime = moment(estimateArrivalTime).utc().format("HH:mm:ss");
        return arrTime === "Invalid date" ? "--:--" : arrTime;
      },
      sorter: (a, b) =>
        a.estimateArrivalTime?.localeCompare(b.estimateArrivalTime),
      // sortDirections: ["ascend"],
      // defaultSortOrder: "ascend",
    },
    {
      title: "DELAY",
      dataIndex: "delay",
      key: "delay",
      render: (delay) => {
        const sign = delay < 0 ? "-" : "";
        const momentObject = moment.duration(Math.abs(delay));

        const hours = momentObject.hours();
        const minutes = momentObject.minutes();
        const seconds = momentObject.seconds();

        return `${sign}${hours}:${minutes}:${seconds}`;
      },
    },
    {
      title: "CALCULATED ARR. TIME (UTC)",
      dataIndex: "calculatedArrivalTime",
      key: "calculatedArrivalTime",
      render: (calculatedArrivalTime) => {
        let arrTime = moment(calculatedArrivalTime).utc().format("HH:mm:ss");
        return arrTime === "Invalid date" ? "--:--" : arrTime;
      },
    },
    {
      title: "REMAINING DISTANCE (NM)",
      dataIndex: "remainingDistance",
      key: "remainingDistance",
      render: (remainingDistance) =>
        (Math.floor(Number(remainingDistance) * 10) / 10).toFixed(1),
      sorter: (a, b) => a.remainingDistance - b.remainingDistance,
    },
    {
      title: "AIRCRAFT STATUS",
      dataIndex: "isOnRoute",
      key: "isOnRoute",
      render: (isOnRoute) => (isOnRoute ? "on route" : "off route"),
    },
    {
      title: "EXPECTED APPROACH TIME",
      dataIndex: "expectedApproachTime",
      key: "expectedApproachTime",
      render: (expectedApproachTime) => {
        let approachTime = moment(expectedApproachTime)
          .utc()
          .format("HH:mm:ss");
        return approachTime === "Invalid date" ? "NIL" : approachTime;
      },
    },
    {
      title: "BASE TURN",
      dataIndex: "baseTurn",
      key: "baseTurn",
    },
    {
      title: "CROSSING ALTITUDE",
      dataIndex: "crossingAltitude",
      key: "crossingAltitude",
    },
  ];

  const getFlights = (params) => {
    setIsLoading(true);
    flights
      .getRadarFlights(params)
      .then((data) => {
        // setData(data);
        setPage(1);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteOnRouteFlights = () => {
    setIsLoading(true);
    flights
      .deleteOnRouteFlights()
      .then((resolve) => {
        console.log("delete success =>", resolve);
      })
      .catch((reject) => {
        console.log("delete error =>", reject);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getModifiedFlightData = useMemo(() => {
    if (!data.length) return [];
    const modifiedFlightData = data?.filter(
      (flight) => flight?.estimateArrivalTime
    );
    return modifiedFlightData.map((flight, index) => ({
      sequence: index + 1,
      ...flight,
    }));
  }, [data]);

  useEffect(() => {
    if (aircraftsData) {
      setData(aircraftsData);
    }
  }, [aircraftsData]);
  return (
    <>
      {isLoading && <Loader />}
      <div className="main-container">
        <div className={`table-container table-open`}>
          {/* <div className="handle-container">
            <div
              className="table-handle"
              // onClick={() => setTableOpen(!tableOpen)}
            >
              <span></span>
              <span></span>
            </div>
            <Icon
              icon="ic:round-refresh"
              fontSize={18}
              style={{ color: "#00838f", cursor: "pointer" }}
              onClick={() => {
                getFlights(params);
              }}
            />
            <Icon
              icon="material-symbols-light:delete"
              fontSize={18}
              style={{ color: "#00838f", cursor: "pointer" }}
              onClick={() => {
                deleteOnRouteFlights();
              }}
            />
          </div> */}
          <div className={`table-content scrollbar-none overflow-auto`}>
            <Table
              columns={column}
              showSorterTooltip={{ placement: "right" }}
              className="aircraft-table m-1"
              rowClassName={(record) => (record.route.length ? "" : "on-hold")}
              size="small"
              dataSource={getModifiedFlightData}
              rowKey={(data) => data?.identification?.id}
              scroll={{
                x: 1600,
                // y: 240
              }}
              sticky={{ offsetScroll: 0 }}
              pagination={{
                pageSize: 30,
                current: page,
                total: getModifiedFlightData?.length,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                onChange: (current) => setPage(current),
              }}
              bordered
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AircraftDataTable;
