import { Button, Card } from "antd";
import "./flights.css";
import React from "react";
import { CloseOutlined } from "@ant-design/icons";

const AddFlights = ({
  isTest,
  isFlightList,
  setIsFlightList,
  flightData,
  flightDataChange,
  addFlight,
}) => {
  return (
    <div
      className={`${
        isTest && !isFlightList ? "show-flight-form" : ""
      } col-12 col-md-4 add-flight`}
    >
      <Card
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2 style={{ margin: 0 }}>Add Flight</h2>
            <Button
              icon={<CloseOutlined />}
              type="text"
              style={{ color: "red" }}
              onClick={() => setIsFlightList(true)}
            />
          </div>
        }
        style={{
          borderRadius: "8px",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div className="row">
          <div className="form-group col-md-6">
            <label htmlFor="lat">Latitude</label>
            <input
              type="number"
              className="form-control"
              id="lat"
              name="lat"
              value={flightData[2]}
              onChange={({ target }) => flightDataChange(2, target.value)}
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="lng">Longitude</label>
            <input
              type="number"
              className="form-control"
              id="lng"
              name="lng"
              value={flightData[3]}
              onChange={({ target }) => flightDataChange(3, target.value)}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-md-6">
            <label htmlFor="callsign">Callsign</label>
            <input
              type="text"
              className="form-control"
              id="callsign"
              name="callsign"
              value={flightData[17]}
              onChange={({ target }) => flightDataChange(17, target.value)}
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="aircraftType">Aircraft Type</label>
            <input
              type="text"
              className="form-control"
              id="aircraftType"
              name="aircraftType"
              value={flightData[9]}
              onChange={({ target }) => flightDataChange(9, target.value)}
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="heading">Heading</label>
          <input
            type="number"
            className="form-control"
            id="heading"
            name="heading"
            value={flightData[4]}
            onChange={({ target }) => flightDataChange(4, target.value)}
          />
        </div>

        <button className="btn btn-primary btn-block mt-2" onClick={addFlight}>
          Add
        </button>
      </Card>
    </div>
  );
};

export default AddFlights;
