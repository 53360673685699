import { Link, Navigate, createBrowserRouter } from "react-router-dom";
import App from "./App";
import AircraftTypes from "./components/AircraftTypes";
import AircraftSeparation from "./components/AircraftSeparation";
import { Result } from "antd";
import TrackRoutines from "./components/TrackRoutines";
import Atcm from "./components/home/Atcm";
import View3D from "./components/map3d/View3D";
import AircraftDataTable from "./components/home/AircraftDataTable";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: (
      <div id="error-page">
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
      </div>
    ),
    children: [
      // {
      //   path: "",
      //   element: <Navigate to="atcm" />,
      // },
      {
        path: "",
        element: <Atcm />,
      },
      {
        path: "3d/:id",
        element: <View3D />,
      },
      {
        path: "air-craft-types",
        element: <AircraftTypes />,
      },
      {
        path: "air-craft-separation",
        element: <AircraftSeparation />,
      },
      {
        path: "track-routines",
        element: <TrackRoutines />,
      },
      {
        path: "no-services",
        element: (
          <div className="d-flex justify-content-center align-items-center h-100">
            <h1>This is services page</h1>
          </div>
        ),
      },
      {
        path: "*",
        element: (
          <div className="d-flex justify-content-center align-items-center h-100">
            <Result
              status="404"
              title="404"
              subTitle="Sorry, the page you visited does not exist."
              extra={<Link to={""} className="btn">Back Home</Link>}
            />
          </div>
        ),
      },
    ],
  },
  {
    path: "aircraft-data-table",
    element: <AircraftDataTable />,
  },
  {
    path: "/login",
    element: (
      <div>
        <h1>This is login Page</h1>
      </div>
    ),
    children: [],
  },
]);

export default router;
